import { render, staticRenderFns } from "./self-help.vue?vue&type=template&id=56ac4aac&scoped=true&"
import script from "./self-help.vue?vue&type=script&lang=js&"
export * from "./self-help.vue?vue&type=script&lang=js&"
import style0 from "./self-help.vue?vue&type=style&index=0&id=56ac4aac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ac4aac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/manage.pc.frontend-purchase-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56ac4aac')) {
      api.createRecord('56ac4aac', component.options)
    } else {
      api.reload('56ac4aac', component.options)
    }
    module.hot.accept("./self-help.vue?vue&type=template&id=56ac4aac&scoped=true&", function () {
      api.rerender('56ac4aac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/logistics/sheet-print/self-help.vue"
export default component.exports